import React from "react"
import * as ReactIcons from "react-icons/fa"

import { Container } from "./styles"

export interface CardProps {
  title: string
  description?: string
  icon: string
}

const Card: React.FC<CardProps> = ({ title, description, icon, ...rest }) => {
  const Icon = ReactIcons[icon]
  return (
    <Container {...rest}>
      <div><Icon /></div>
      <h2>{title}</h2>
    </Container>
  )
}

export default Card
