import React from "react"
import Image from "gatsby-image"

import { Container, Content, CasesWrapper, CaseItem } from "./styles"

interface CaseSectionProps {
  title: string;
  cases: ICases[];
}

export interface ICases {
  name: string;
  title: string;
  description: string;
  image: any;
}

const CaseSection: React.FC<CaseSectionProps> = ({ title, cases, ...rest }) => {
  return (
    <Container>
    <Content {...rest}>
      <h1>{title}</h1>
      <CasesWrapper>
        {cases && cases.map(({ name, title, description, image }, index) => {
          const ComponentImage = typeof image === "string" 
            ? () => <img src={image} className="gatsby-image-wrapper" /> 
            : () => <Image fluid={image.childImageSharp.fluid} />
          return (
            <CaseItem key={index}>
              <div 
                data-sal="slide-right"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <h3>{name}</h3>
                <h2>{title}</h2>
                <p>{description}</p>
              </div>
              <ComponentImage 
                data-sal="slide-left"
                data-sal-easing="ease"
                data-sal-duration="1000"
              />
            </CaseItem>
          )
        })}
      </CasesWrapper>
    </Content>
    </Container>
  )
}

export default CaseSection
