import styled from "styled-components"
import myTheme from "../../../theme"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  flex-grow: 1;

  max-width: 31%;

  padding: 30px 40px;
  margin: 20px 0;

  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  h2 {
    margin: 0;
    font-size: 18px;
  }

  @media (max-width: ${myTheme.responsive.container}) {
    flex-grow: 0;

    max-width: 100%;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    color: ${myTheme.colors.primary};
    font-size: 46px;
  }
`
