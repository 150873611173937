import React from "react"
import * as ReactIcons from "react-icons/fa"

import { Container, Header } from "./styles"

export interface CardProps {
  title: string
  description: string
  icon: string
}

const Card: React.FC<CardProps> = ({ title, description, icon, ...rest }) => {
  const Icon = ReactIcons[icon]
  return (
    <Container {...rest}>
      <Header>
        <Icon size={46} />
        <h2>{title}</h2>
      </Header>
      <p>{description}</p>
    </Container>
  )
}

export default Card
