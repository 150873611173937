import React from "react"
import { graphql } from "gatsby"

import Layout from "./main"
import SEO from "../components/seo"
import Hero from "../components/Organisms/Hero"
import FeatureSideImageSection from "../components/Organisms/FeatureSideImageSection"
import CardsSection from "../components/Organisms/CardsSection"
import CaseSection, { ICases } from "../components/Organisms/CaseSection"
import LogoCompaniesSection from "../components/Organisms/LogoCompaniesSection"
import ContactSection from "../components/Organisms/ContactSection"

import { CardProps } from "../components/Molecules/Card"

interface QuemServimosLayoutProps {
  name: string;
  hero_title: string;
  hero_description: string;
  hero_buttonText: string;
  hero_buttonLink?: string;
  fluid_hero_background: string;
  features: IFeatures[];
  solutions_title: string
  solutions_cards: CardProps[];
  case_title: string;
  cases: ICases[];
  contactFormImage?: any;
}

interface IFeatures {
  description: string;
  title: string;
  image: { childImageSharp: { fluid: any } }
}

export const QuemServimosLayout = ({
  name,
  hero_title,
  hero_description,
  hero_buttonText,
  hero_buttonLink,
  fluid_hero_background,
  features,
  solutions_title,
  solutions_cards,
  case_title,
  cases,
  contactFormImage
}: QuemServimosLayoutProps) => (
  <>
    <SEO title={name} description={hero_description} />
    <Hero
      title={hero_title}
      description={hero_description}
      button={{
        text: hero_buttonText,
        color: 'primary',
        link: hero_buttonLink
      }}
      fluidImage={fluid_hero_background}
    />

    {features.map((feature, index) => (
      <FeatureSideImageSection
        id={index?undefined:"features"}
        description={feature.description}
        title={feature.title}
        image={feature.image.childImageSharp.fluid}
        key={index}
        reverse={index%2 === 1}
        alternativeBg
      />
    ))}

    <CardsSection
      title={solutions_title}
      cards={solutions_cards}
    />

    <CaseSection
      title={case_title}
      cases={cases}
    />

    <LogoCompaniesSection />

    {contactFormImage && <ContactSection
        fluidImage={contactFormImage}
        phoneNumberLink="tel:027996433420"
        whatsappNumberLink="https://api.whatsapp.com/send?phone=+5527996433420&text=Ol%C3%A1.%20Eu%20tenho%20interesse%20nos%20Servi%C3%A7os%20da%20Ag%C3%AAncia."
      />}
  </>
)

const QuemServimos = ({ data }) => {
  const {
    name,
    hero_title,
    hero_description,
    hero_buttonText,
    hero_buttonLink,
    hero_background: { childImageSharp: { fluid: fluid_hero_background } },
    features,
    solutions_title,
    solutions_cards,
    case_title,
    cases,
  } = data.markdownRemark.frontmatter

  const contactFormImage = data.contactFormImage.childImageSharp.fluid;

  return (
    <Layout>
      <QuemServimosLayout
        name={name}
        hero_title={hero_title}
        features={features}
        fluid_hero_background={fluid_hero_background}
        hero_buttonText={hero_buttonText}
        hero_description={hero_description}
        solutions_title={solutions_title}
        solutions_cards={solutions_cards}
        case_title={case_title}
        cases={cases}
        contactFormImage={contactFormImage}
        hero_buttonLink={hero_buttonLink}
      />
    </Layout>

  )
}

export const query = graphql`
  query QuemServimos($slug: String!) {
    contactFormImage: file(
      relativePath: { eq: "banners/contact-banner.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 1350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        name
        hero_title
        hero_description
        hero_buttonText
        hero_buttonLink
        hero_background {
          childImageSharp {
            fluid(base64Width: 600, quality: 50) {
              base64
            }
          }
        }
        features {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        solutions_title
        solutions_cards {
          title
          description
          icon
        }
        case_title
        cases {
          name
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default QuemServimos
