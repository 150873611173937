import styled from "styled-components"
import myTheme from "../../../theme"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 1200px;
  padding: 30px;
  margin: auto;

  @media (max-width: ${myTheme.responsive.container}) {
  }
`

export const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${myTheme.responsive.container}) {
    flex-direction: column;
  }
`