import styled, { css } from "styled-components"

import myTheme from "../../../theme"

interface ContainerProps {
  alternativeBg?: boolean
}

export const Container = styled.div<ContainerProps>`
  background-color: white;
  padding: 60px 0;
  -webkit-font-smoothing: antialiased;
  white-space: pre-wrap;

  ${props =>
    props.alternativeBg &&
    css`
      background-color: #fafafb;
    `}

  @media (max-width: ${myTheme.responsive.container}) {
    padding: 0;
  }
`

interface ContentProps {
  reverse?: boolean
  mobileReverse?: boolean
  alternativeBg?: boolean
  floatImage?: boolean
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  max-width: 1200px;
  padding: 0 30px;
  margin: auto;
  overflow: hidden;

  background-color: white;

  h2 {
    font-size: 32px;
    line-height: 1.2em;
    margin-top: 0;
  }

  ${props =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `};

  ${props =>
    props.alternativeBg &&
    css`
      background-color: #fafafb;
    `}

    ${props =>
    props.floatImage &&
    css`
      overflow: visible;
    `}

  div {
    width: 50%;
    padding: 0px 20px;
  }

  .gatsby-image-wrapper {
    height: 300px;
    ${props =>
      props.floatImage &&
      css`
        margin: 20px 0 -120px 0;
      `}

    img {
      object-fit: contain !important;
    }
  }

  @media (max-width: ${myTheme.responsive.container}) {
    margin: 0 auto;
    padding: 30px;

    flex-direction: column;

    div {
      width: 100%;
      margin: 20px auto;
      padding: 0;
    }

    .gatsby-image-wrapper {
      margin: -30px 0;
    }

    ${props =>  props.mobileReverse &&
      css`
        flex-direction: column-reverse;
        .gatsby-image-wrapper {
          margin: 0 0 -20px 0;
        }
      `
    };
  }
`
