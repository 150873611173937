import React from "react"

import Card, { CardProps } from "../../Molecules/Card"
import CardAlternative from "../../Molecules/CardAlternative"
import { Container, CardsWrapper } from "./styles"

interface CardSectionProps {
  title?: string
  cards: CardProps[]
  alternative?: boolean
}

const CardSection: React.FC<CardSectionProps> = ({ title, cards, alternative, ...rest }) => {
  const CardComponent = alternative ? CardAlternative : Card
  return (
    <Container {...rest}>
      <h1>{title}</h1>
      <CardsWrapper>
        {cards && cards.map(({ title, description, icon }, index) => (
          <CardComponent
            key={index}
            title={title}
            description={description}
            icon={icon}
          />
        ))}
      </CardsWrapper>
    </Container>
  )
}

export default CardSection
