import styled from "styled-components"
import myTheme from "../../../theme"

export const Container = styled.div`
  background-color: #fafafb;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 1200px;
  padding: 30px;
  margin: auto;
  overflow: hidden;
`


export const CasesWrapper = styled.div`
  h3 {
    color: ${myTheme.colors.primary};
    margin-bottom: 0;
  }

  h2 {
    margin-top: 0;
  }
`

export const CaseItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;

  div {
    width: 50%;
  }

  p {
    -webkit-font-smoothing: antialiased;
    white-space: pre-wrap;
  }

  .gatsby-image-wrapper {
    width: 50%;

    img {
      object-fit: contain !important;
    }
  }

  @media (max-width: ${myTheme.responsive.container}) {
    flex-direction: column-reverse;

    div {
      width: 100%;
    }

    .gatsby-image-wrapper {
      width: 100%;
    }
  }
`