import React from "react"
import { Button } from "@material-ui/core"
import Image from "gatsby-image"
import { Link } from "gatsby"

import { Container, Content } from "./styles"

interface FeatureSideImageSectionProps {
  title: string
  description: string
  buttonText?: string
  buttonLink?: string
  image?: any
  reverse?: boolean
  mobileReverse?: boolean
  alternativeBg?: boolean
  floatImage?: any
  id?: string;
}

const FeatureSideImageSection = ({
  title,
  description,
  buttonText,
  buttonLink,
  reverse,
  mobileReverse,
  alternativeBg,
  image,
  floatImage,
  ...rest
}: FeatureSideImageSectionProps) => {
  const ComponentImage = typeof image === "string" ? ({ ...rest }) => <img src={image} className="gatsby-image-wrapper" { ...rest } /> : ({ ...rest }) => <Image fluid={image} { ...rest } />
  return (
    <Container  alternativeBg={alternativeBg} {...rest} >
      <Content reverse={reverse} floatImage={floatImage} alternativeBg={alternativeBg} mobileReverse={mobileReverse}>
        <div>
          <h2>{title.split("\\n").map((result:String) =>`${result}\n`)}</h2>
          <p>{description.split("\\n").map((result:String) =>`${result}\n`)}</p>
          <Link to={buttonLink || "#"}>
            {buttonText && <Button variant="contained" color="primary">
              {buttonText}
            </Button>}
          </Link>
        </div>
        <ComponentImage />
      </Content>
    </Container>
  )
}

export default FeatureSideImageSection
