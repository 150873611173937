import styled from "styled-components"
import myTheme from "../../../theme"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 23%;

  padding: 30px;
  margin: 20px 0;

  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  h2 {
    font-size: 18px;
    text-align: center;
  }

  div {
    border: solid 2px ${myTheme.colors.primary};
    border-radius: 50%;
    padding: 17px 20px;
  }

  svg {
    color: ${myTheme.colors.primary};
    font-size: 60px;
  }

  @media (max-width: ${myTheme.responsive.container}) {
    flex-grow: 0;

    width: 100%;
  }
`
